(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MaterialIbqDetailController', MaterialIbqDetailController);

    MaterialIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Material', 'MaterialFile', 'Type', 'Publisher', 'Person', 'PortalRole'];

    function MaterialIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Material, MaterialFile, Type, Publisher, Person, PortalRole) {
        var vm = this;

        vm.material = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:materialUpdate', function(event, result) {
            vm.material = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
