(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .factory('PartSearch', PartSearch);

    PartSearch.$inject = ['$resource'];

    function PartSearch($resource) {
        var resourceUrl =  'api/_search/parts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
