(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('PartIbqDetailController', PartIbqDetailController);

    PartIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Part', 'MusicalPiece', 'Publisher', 'Level', 'State', 'Instrument'];

    function PartIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Part, MusicalPiece, Publisher, Level, State, Instrument) {
        var vm = this;

        vm.part = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:partUpdate', function(event, result) {
            vm.part = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
