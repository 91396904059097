(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('DiscIbqDetailController', DiscIbqDetailController);

    DiscIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Disc', 'Audio', 'Collection', 'Participation'];

    function DiscIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Disc, Audio, Collection, Participation) {
        var vm = this;

        vm.disc = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:discUpdate', function(event, result) {
            vm.disc = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
