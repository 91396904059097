(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('PortalRoleDetailController', PortalRoleDetailController);

    PortalRoleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PortalRole', 'MusicalPiece'];

    function PortalRoleDetailController($scope, $rootScope, $stateParams, previousState, entity, PortalRole, MusicalPiece) {
        var vm = this;

        vm.portalRole = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:portalRoleUpdate', function(event, result) {
            vm.portalRole = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
