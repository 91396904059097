(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .filter('propsFilter', function() {
            return function(items, props) {
                var out = [];

                if (angular.isArray(items)) {
                    var keys = Object.keys(props);

                    items.forEach(function(item) {
                        var itemMatches = false;

                        for (var i = 0; i < keys.length; i++) {
                            var prop = keys[i];
                            var text = props[prop].toLowerCase();
                            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                                itemMatches = true;
                                break;
                            }
                        }

                        if (itemMatches) {
                            out.push(item);
                        }
                    });
                } else {
                    // Let the output be the input untouched
                    out = items;
                }

                return out;
            };
        })
        .controller('PersonIbqDialogController', PersonIbqDialogController);

    PersonIbqDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Person', 'Country', 'Audio', 'Photo', 'Role', 'Specialty', 'Material'];

    function PersonIbqDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Person, Country, Audio, Photo, Role, Specialty, Material) {
        var vm = this;

        vm.person = entity;
        vm.clearDialog = clearDialog;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;

        vm.countries = Country.query();
        vm.countries.$promise.then(function(countries){
            vm.country=countries.find(function (country) {
                return country.id===vm.person.countryId;
            })
        });
        vm.audio = Audio.query();
        vm.photos = Photo.query();
        vm.roles = Role.query();
        vm.specialties = Specialty.query();
        vm.materials = Material.query();


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clearDialog () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
             vm.isSaving = true;
             vm.person.countryId=vm.country && vm.country.id;

             if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }
        }



        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:personUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPictureFile = function ($file, person) {
            if ($file && $file.$error === 'pattern') {

                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        person.pictureFile = base64Data;
                        person.pictureFileContentType = $file.type;
                        person.picturePath=$file.name;

                    });
                });
            }
        };
        vm.datePickerOpenStatus.birthDate = false;
        vm.datePickerOpenStatus.deathDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }



        //New


        vm.setInputFocus = function (){
            $scope.$broadcast('UiSelectDemo1');
        };


        vm.clear = function() {
            //  vm.person.selected = undefined;
            //  vm.address.selected = undefined;
            //  vm.country.selected = undefined;
        };

        vm.someGroupFn = function (item){

            if (item.name[0] >= 'A' && item.name[0] <= 'M')
                return 'From A - M';

            if (item.name[0] >= 'N' && item.name[0] <= 'Z')
                return 'From N - Z';

        };

        vm.firstLetterGroupFn = function (item){
            return item.name[0];
        };

        vm.reverseOrderFilterFn = function(groups) {
            return groups.reverse();
        };

        vm.counter = 0;
        vm.onSelectCallback = function (item, model){
            vm.counter++;
            vm.eventResult = {item: item, model: model};
        };

        vm.removed = function (item, model) {
            vm.lastRemoved = {
                item: item,
                model: model
            };
        };

        vm.tagTransform = function (newTag) {
            var item = {
                name: newTag,
                email: newTag.toLowerCase()+'@email.com',
                age: 'unknown',
                country: 'unknown'
            };

            return item;
        };

    }

})();

