(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
            .factory('PortalRoleSync', PortalRoleSync);

    PortalRoleSync.$inject = ['$resource'];

    function PortalRoleSync($resource) {
        var resourceUrl =  'api/portal-roles/sync';

        return $resource(resourceUrl, {}, {
            'sync': { method: 'GET'}
        });
    }
})();
