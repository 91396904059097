(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicSheetIbqDialogController', MusicSheetIbqDialogController);

    MusicSheetIbqDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'MusicSheet', 'MusicalPiece', 'Publisher', 'Level', 'State', 'MusicalArrangementType', 'Person', 'PortalRole', 'Country', 'Ensamble'];

    function MusicSheetIbqDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, MusicSheet, MusicalPiece, Publisher, Level, State, MusicalArrangementType, Person, PortalRole, Country, Ensamble ) {
        var vm = this;

        vm.musicSheet = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.musicalpieces = MusicalPiece.query();
        vm.publishers = Publisher.query();
        vm.levels = Level.query();
        vm.states = State.query();
        vm.musicalarrangementtypes = MusicalArrangementType.query();
        vm.people = Person.query();
        vm.portalroles = PortalRole.query();
        vm.countries = Country.query();
        vm.ensambles = Ensamble.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.musicSheet.id !== null) {
                MusicSheet.update(vm.musicSheet, onSaveSuccess, onSaveError);
            } else {
                MusicSheet.save(vm.musicSheet, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:musicSheetUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setMusicSheetFile = function ($file, musicSheet) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        musicSheet.musicSheetFile = base64Data;
                        musicSheet.musicSheetFileContentType = $file.type;
                        musicSheet.musicSheetFilePath=$file.name;
                    });
                });
            }
        };

        vm.setMusicSheetCoverFile = function ($file, musicSheet) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        musicSheet.musicSheetCoverFile = base64Data;
                        musicSheet.musicSheetCoverFileContentType = $file.type;
                        musicSheet.cover=$file.name;
                    });
                });
            }
        };

        vm.datePickerOpenStatus.editionDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
