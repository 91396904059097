(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('VideoIbqDetailController', VideoIbqDetailController);

    VideoIbqDetailController.$inject = ['$scope', '$sce', '$rootScope', '$stateParams', 'previousState', 'entity', 'Video', 'VideoType', 'MusicalPiece', 'Language', 'Person', 'Copyright'];

    function VideoIbqDetailController($scope, $sce, $rootScope, $stateParams, previousState, entity, Video, VideoType, MusicalPiece, Language, Person, Copyright) {
        var vm = this;
        vm.trustAsHtml=$sce.trustAsHtml;
        vm.video = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:videoUpdate', function(event, result) {
            vm.video = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
