(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('SpecialtyIbqDeleteController',SpecialtyIbqDeleteController);

    SpecialtyIbqDeleteController.$inject = ['$uibModalInstance', 'entity', 'Specialty'];

    function SpecialtyIbqDeleteController($uibModalInstance, entity, Specialty) {
        var vm = this;

        vm.specialty = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Specialty.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
