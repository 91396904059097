(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('StateIbqDeleteController',StateIbqDeleteController);

    StateIbqDeleteController.$inject = ['$uibModalInstance', 'entity', 'State'];

    function StateIbqDeleteController($uibModalInstance, entity, State) {
        var vm = this;

        vm.state = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            State.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
