(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('VideoIbqDialogController', VideoIbqDialogController);

    VideoIbqDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Video', 'VideoType', 'MusicalPiece', 'Language', 'Person', 'Copyright'];

    function VideoIbqDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Video, VideoType, MusicalPiece, Language, Person, Copyright) {
        var vm = this;

        vm.video = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.videotypes = VideoType.query();
        vm.musicalpieces = MusicalPiece.query();
        vm.languages = Language.query();
        vm.people = Person.query();
        vm.copyrights = Copyright.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.video.id !== null) {
                Video.update(vm.video, onSaveSuccess, onSaveError);
            } else {
                Video.save(vm.video, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:videoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
