(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('PartIbqDialogController', PartIbqDialogController);

    PartIbqDialogController.$inject = ['$http','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Part', 'MusicalPiece', 'Publisher', 'Level', 'State', 'Instrument'];

    function PartIbqDialogController ($http ,$timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Part, MusicalPiece, Publisher, Level, State, Instrument) {
        var vm = this;

        vm.part = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        // vm.musicalpieces = MusicalPiece.query();
        vm.musicalpieces = [];
        vm.publishers = Publisher.query();
        vm.levels = Level.query();
        vm.states = State.query();
        vm.instruments = Instrument.query();

        //vm.instruments = this.getAllInstruments;

        $http({
            method: 'GET',
            url: 'api/musical-pieces?page=0&size=1000'
        }).success(function(data) {
            vm.musicalpieces = data;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getAllInstruments () {
            $http.get("api/instruments?page=0&size=100", null).then(function (response) {vm.instruments=response ; });
        }

        function save () {
            vm.isSaving = true;
            if (vm.part.id !== null) {
                Part.update(vm.part, onSaveSuccess, onSaveError);
            } else {
                Part.save(vm.part, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:partUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPartFile = function ($file, part) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        part.partFile = base64Data;
                        part.partFileContentType = $file.type;
                        part.partPath = $file.name;
                    });
                });
            }
        };

    }
})();
