(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalPieceIbqDeleteController',MusicalPieceIbqDeleteController);

    MusicalPieceIbqDeleteController.$inject = ['$uibModalInstance', 'entity', 'MusicalPiece'];

    function MusicalPieceIbqDeleteController($uibModalInstance, entity, MusicalPiece) {
        var vm = this;

        vm.musicalPiece = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MusicalPiece.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
