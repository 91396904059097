(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalArrangementTypeIbqDeleteController',MusicalArrangementTypeIbqDeleteController);

    MusicalArrangementTypeIbqDeleteController.$inject = ['$uibModalInstance', 'entity', 'MusicalArrangementType'];

    function MusicalArrangementTypeIbqDeleteController($uibModalInstance, entity, MusicalArrangementType) {
        var vm = this;

        vm.musicalArrangementType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MusicalArrangementType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
