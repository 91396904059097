(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicSheetIbqDetailController', MusicSheetIbqDetailController);

    MusicSheetIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MusicSheet', 'MusicalPiece', 'Publisher', 'Level', 'State', 'MusicalArrangementType', 'Person', 'PortalRole', 'Country', 'Ensamble'];

    function MusicSheetIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MusicSheet, MusicalPiece, Publisher, Level, State, MusicalArrangementType, Person, PortalRole, Country, Ensamble) {
        var vm = this;

        vm.musicSheet = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:musicSheetUpdate', function(event, result) {
            vm.musicSheet = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
