(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalArrangementIbqDialogController', MusicalArrangementIbqDialogController);

    MusicalArrangementIbqDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'MusicalArrangement', 'MusicalArrangementType', 'Level', 'State', 'Publisher', 'Person'];

    function MusicalArrangementIbqDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, MusicalArrangement, MusicalArrangementType, Level, State, Publisher, Person) {
        var vm = this;

        vm.musicalArrangement = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.musicalarrangementtypes = MusicalArrangementType.query();
        vm.levels = Level.query();
        vm.states = State.query();
        vm.publishers = Publisher.query();
        vm.people = Person.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.musicalArrangement.id !== null) {
                MusicalArrangement.update(vm.musicalArrangement, onSaveSuccess, onSaveError);
            } else {
                MusicalArrangement.save(vm.musicalArrangement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:musicalArrangementUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setArrangementFile = function ($file, musicalArrangement) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        musicalArrangement.arrangementFile = base64Data;
                        musicalArrangement.arrangementFileContentType = $file.type;
                        musicalArrangement.arrangementFilePath = $file.name;
                    });
                });
            }
        };

    }
})();
