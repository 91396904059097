(function () {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('InstrumentController', InstrumentController);

    InstrumentController.$inject = ['$http', '$state', '$scope', 'Upload','DataUtils', 'Instrument', 'InstrumentSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function InstrumentController($http, $state, $scope, Upload, DataUtils, Instrument, InstrumentSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.myFile;

        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                InstrumentSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Instrument.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.instruments = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.setCSVFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {

                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {

                        $http.post("api/instruments/import", base64Data , null).then(function (response) {
                            loadAll();
                        });
                    });
                });


            }
        };

        $scope.upload = function (file) {
            Upload.upload({
                url: 'api/instruments/import',
                data: {file: file, 'username': ""}
            }).then(function (resp) {
                //console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                loadAll();
            }, function (resp) {
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
    }
})();
