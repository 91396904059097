(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('GraphicArchiveItemIbqDeleteController',GraphicArchiveItemIbqDeleteController);

    GraphicArchiveItemIbqDeleteController.$inject = ['$uibModalInstance', 'entity', 'GraphicArchiveItem'];

    function GraphicArchiveItemIbqDeleteController($uibModalInstance, entity, GraphicArchiveItem) {
        var vm = this;

        vm.graphicArchiveItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GraphicArchiveItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
