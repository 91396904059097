(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('ParticipationIbqDetailController', ParticipationIbqDetailController);

    ParticipationIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Participation', 'Person', 'Role', 'MusicalPiece'];

    function ParticipationIbqDetailController($scope, $rootScope, $stateParams, previousState, entity, Participation, Person, Role, MusicalPiece) {
        var vm = this;

        vm.participation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:participationUpdate', function(event, result) {
            vm.participation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
