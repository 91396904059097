(function () {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('PortalRoleController', PortalRoleController);

    PortalRoleController.$inject = ['$http', '$q', '$timeout', 'PortalRoleSync', 'PortalRole', 'PortalRoleSearch'];

    function PortalRoleController($http, $q, $timeout, PortalRoleSync, PortalRole, PortalRoleSearch) {

        var vm = this;

        vm.portalRoles = [];
        vm.clear = clear;
        vm.sync = sync;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {

            PortalRole.query(function (result) {
                vm.portalRoles = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PortalRoleSearch.query({query: vm.searchQuery}, function (result) {
                vm.portalRoles = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }


        function sync() {

            $http.get("api/portal-roles/sync").then(function (response) {   vm.loadAll(); });

        }

    }
})();
