(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('AudioIbqDialogController', AudioIbqDialogController);

    AudioIbqDialogController.$inject = ['$sanitize','$timeout', '$scope', '$stateParams', '$uibModalInstance','DataUtils', 'entity', 'Audio', 'Disc', 'MusicalPiece', 'Person', 'Copyright'];

    function AudioIbqDialogController ($sanitize, $timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Audio, Disc, MusicalPiece, Person, Copyright) {
        var vm = this;
        vm.audio = entity;
        vm.clear = clear;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.discs = Disc.query();
        vm.musicalpieces = MusicalPiece.query();
        vm.people = Person.query();
        vm.copyrights = Copyright.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            //vm.audio.audioFile=$sanitize(vm.audio.audioFile);

            if (vm.audio.id !== null) {
                Audio.update(vm.audio, onSaveSuccess, onSaveError);
            } else {
                Audio.save(vm.audio, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:audioUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setAudioFile = function ($file, audioFile) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        audioFile.afile = base64Data;
                        audioFile.audioContentType = $file.type;
                        audioFile.audioFile=$file.name;
                    });
                });
            }
        };


    }
})();
