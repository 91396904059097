(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalArrangementIbqDetailController', MusicalArrangementIbqDetailController);

    MusicalArrangementIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MusicalArrangement', 'MusicalArrangementType', 'Level', 'State', 'Publisher', 'Person'];

    function MusicalArrangementIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MusicalArrangement, MusicalArrangementType, Level, State, Publisher, Person) {
        var vm = this;

        vm.musicalArrangement = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:musicalArrangementUpdate', function(event, result) {
            vm.musicalArrangement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
