(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalPieceIbqDialogController', MusicalPieceIbqDialogController);

    MusicalPieceIbqDialogController.$inject = ['$timeout', '$http', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'MusicalPiece', 'MusicSheet', 'Role', 'Person', 'Participation','Album','PortalRole', 'Ensamble'];

    function MusicalPieceIbqDialogController ($timeout, $http, $scope, $stateParams, $uibModalInstance, DataUtils, entity, MusicalPiece, MusicSheet, Role, Person, Participation, Album, PortalRole, Ensamble) {
        var vm = this;

        vm.musicalPiece = entity;
        vm.clear = clear;
        //vm.datePickerOpenStatus = {};
        vm.participant = {};
        vm.participant.show=false;
        //vm.participation = [];
        vm.addParticipant=addParticipant;
        vm.deleteParticipant=deleteParticipant;
        //vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.participations = Participation.query();
        vm.musicsheets = MusicSheet.query();
        vm.roles = Role.query();
        vm.people = Person.query();
        vm.albums = Album.query();
        vm.portalroles = PortalRole.query();
        vm.ensambles = Ensamble.query();

        /*Person.query().$promise.then(function(people){
            vm.people=people.find(function (people) {
                return people.id===vm.person.countryId;
            })
        });*/

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.musicalPiece.id !== null) {
                MusicalPiece.update(vm.musicalPiece, onSaveSuccess, onSaveError);
            } else {
                MusicalPiece.save(vm.musicalPiece, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('virtuallibraryApp:musicalPieceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


       // vm.datePickerOpenStatus.compositionDate = false;

        vm.setCover = function ($file, musicalPiece) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        musicalPiece.cover = base64Data;
                        musicalPiece.coverContentType = $file.type;
                        musicalPiece.musicalPiecePath=$file.name;
                    });
                });
            }
        };


        /*function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }*/

        function addParticipant () {
            vm.participant.personName=vm.participant.person.name+' '+vm.participant.person.lastName;
            var rolesList = new Array;
            vm.participant.roles.forEach(function(element) {
                rolesList.push(element.roleName);
            });

            rolesList=rolesList.join(', ');

            var rolesText='('+rolesList+')';
            vm.participant.title=vm.participant.personName+' '+rolesText;
            vm.participant.personId=vm.participant.person.id;

            console.log(vm.participant);

            //Participation.save(vm.participant);

            $http.post("api/participations/",vm.participant,null).then(function (response) {
                vm.participations=Participation.query();
               // $http.get('api/participations/', null).then(function (response){vm.participations=response; })
            });

            vm.participant={};
            !vm.participant.show;

        }

        function deleteParticipant (participant) {
            vm.participation.splice(vm.participation.find(function(p){
                return p.person.id===participant.person.id;
            }),1);

        }
    }
})();
