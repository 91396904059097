(function() {
    'use strict';

    angular.module('virtuallibraryApp').controller('ParticipationIbqDialogController', ParticipationIbqDialogController);

    ParticipationIbqDialogController.$inject = [
        '$http',
        '$timeout',
        '$scope',
        '$stateParams',
        '$uibModalInstance',
        'entity',
        'Participation',
        'Person',
        'Role',
        'MusicalPiece'
    ];

    function ParticipationIbqDialogController(
    $http,
    $timeout,
    $scope,
    $stateParams,
    $uibModalInstance,
    entity,
    Participation,
    Person,
    Role,
    MusicalPiece
  ) {
        var vm = this;
        vm.person = {};
        vm.person.fullName = '';
        vm.participation = entity;
        vm.clear = clear;
        vm.save = save;
    // vm.people = Person.query();
        vm.people = [];
        vm.roles = Role.query();
        vm.musicalpieces = MusicalPiece.query();

        $http({
            method: 'GET',
            url: 'api/people?page=0&size=500'
        }).success(function(data) {
            vm.people = data;
        });

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            generateTitle();
            if (vm.participation.id !== null) {
                Participation.update(vm.participation, onSaveSuccess, onSaveError);
            } else {
                Participation.save(vm.participation, onSaveSuccess, onSaveError);
            }
        }

        function generateTitle() {
            vm.participation.title = '';

            vm.people.forEach(function(element) {
                if (element.id === vm.participation.personId) {
                    vm.participation.title = element.name + ' ' + element.lastName;
                }
            });

            var rolesList = new Array();
            vm.participation.roles.forEach(function(element) {
                rolesList.push(element.roleName);
            });

      //rolesList=rolesList.join(', ');
      //var rolesText='( '+rolesList+' )';
            vm.participation.title += '( ' + rolesList.join(', ') + ' )';
        }

        function onSaveSuccess(result) {
            $scope.$emit('virtuallibraryApp:participationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
