(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('MusicalPieceIbqDetailController', MusicalPieceIbqDetailController);

    MusicalPieceIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MusicalPiece', 'MusicSheet', 'Participation', 'Album', 'PortalRole', 'Ensamble'];

    function MusicalPieceIbqDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MusicalPiece, MusicSheet, Participation, Album, PortalRole, Ensamble) {
        var vm = this;

        vm.musicalPiece = entity;
        vm.previousState = previousState.name;

        //vm.byteSize = DataUtils.byteSize;
        //vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:musicalPieceUpdate', function(event, result) {
            vm.musicalPiece = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
