(function() {
    'use strict';

    angular
        .module('virtuallibraryApp')
        .controller('PersonRoleIbqDetailController', PersonRoleIbqDetailController);

    PersonRoleIbqDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PersonRole', 'Person'];

    function PersonRoleIbqDetailController($scope, $rootScope, $stateParams, previousState, entity, PersonRole, Person) {
        var vm = this;

        vm.personRole = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('virtuallibraryApp:personRoleUpdate', function(event, result) {
            vm.personRole = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
